import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { Title, Section, Box, Text } from '../../components/Core'

const SectionStyled = styled(Section)`
  padding-top: 90px;
`

const FaqCard = styled(Box)`
  position: relative;
  &::before {
    content: '\f061';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    min-width: 30px;
    min-height: 30px;
    max-height: 30px;
    font-size: 15px;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.light};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    position: absolute;
    left: 0;
  }
`

const FaqTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.light};
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 24px;
  line-height: 2rem;
`

const Faq = () => (
  <>
    {/* <!-- Faq section --> */}
    <SectionStyled bg="dark" pb="0 !important">
      <Container>
        <Row>
          <Col lg="6" className="mb-5 faq-item">
            <FaqCard>
              <div className="ml-5">
                <FaqTitle>
                  <FormattedMessage id="faq_title_1" />
                </FaqTitle>
                <Text variant="small" color="light" opacity={0.7}>
                  <FormattedMessage id="faq_answer_1" />
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="6" className="mb-5 faq-item">
            <FaqCard>
              <div className="ml-5">
                <FaqTitle>
                  {' '}
                  <FormattedMessage id="faq_title_2" />
                </FaqTitle>
                <Text variant="small" color="light" opacity={0.7}>
                  <FormattedMessage id="faq_answer_2" />
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="6" className="mb-5 faq-item">
            <FaqCard>
              <div className="ml-5">
                <FaqTitle>
                  {' '}
                  <FormattedMessage id="faq_title_3" />
                </FaqTitle>
                <Text variant="small" color="light" opacity={0.7}>
                  <FormattedMessage id="faq_answer_3" />
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="6" className="mb-5 faq-item">
            <FaqCard>
              <div className="ml-5">
                <FaqTitle>
                  {' '}
                  <FormattedMessage id="faq_title_4" />
                </FaqTitle>
                <Text variant="small" color="light" opacity={0.7}>
                  <FormattedMessage id="faq_answer_4" />
                </Text>
              </div>
            </FaqCard>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
)

export default Faq
