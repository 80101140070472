import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { Section, Box, Text } from '../../components/Core'
import feature_1 from '../../assets/image/svg/features/feature_1.svg'
import feature_2 from '../../assets/image/svg/features/feature_2.svg'
import feature_3 from '../../assets/image/svg/features/feature_3.svg'
import feature_4 from '../../assets/image/svg/features/feature_4.svg'
import feature_5 from '../../assets/image/svg/features/feature_5.svg'
import feature_6 from '../../assets/image/svg/features/feature_6.svg'

const FeatureCard = ({
  color = 'primary',
  iconName,
  imageUrl,
  title,
  children,
  ...rest
}) => (
  <Box
    pt="15px"
    px="30px"
    data-aos="fade-left"
    data-aos-duration="750"
    data-aos-delay="1000"
    data-aos-once="true"
    borderRadius={10}
    mb={4}
    {...rest}
  >
    <Box
      size={113}
      color={color}
      fontSize="28px"
      className="d-flex justify-content-center align-items-center mx-auto"
    >
      <img src={imageUrl} alt="" className="img-fluid" />
    </Box>
    <div className="text-center">
      <Text as="h3" fontSize={4} fontWeight={500} my={3}>
        <FormattedMessage id={title} />
      </Text>
      <Text fontSize={2} lineHeight={1.75} color="darkShade">
        {children}
      </Text>
    </div>
  </Box>
)

const Feature = () => (
  <>
    {/* <!-- Feature section --> */}
    <Section className="position-relative pt-5">
      <Container>
        <Row className="align-items-start justify-content-center">
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="secondary"
              iconName="icon-sidebar-2"
              imageUrl={feature_1}
              title="feature_title_1"
            >
              <FormattedMessage id="feature_1_desc" />
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="primary"
              iconName="icon-layout-11"
              imageUrl={feature_2}
              title="feature_title_2"
            >
              <FormattedMessage id="feature_2_desc" />
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="secondary"
              iconName="icon-airplane-2"
              imageUrl={feature_3}
              title="feature_title_3"
            >
              <FormattedMessage id="feature_3_desc" />
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="primary"
              iconName="icon-voice-recognition-2"
              imageUrl={feature_4}
              title="feature_title_4"
            >
              <FormattedMessage id="feature_4_desc" />
            </FeatureCard>
          </Col>
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="warning"
              iconName="icon-bookmark-2-2"
              imageUrl={feature_5}
              title="feature_title_5"
            >
              <FormattedMessage id="feature_5_desc" />
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="secondary"
              iconName="icon-infinite"
              imageUrl={feature_6}
              title="feature_title_6"
            >
              <FormattedMessage id="feature_6_desc" />
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
)

export default Feature
