import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import { Title, Button, Box, Text } from "../../components/Core";

const CTA = () => (
  <>
    {/* <!-- CTA section --> */}
    <Box bg="dark" className="position-relative" py={[4, null, null, 5, 6]}>
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="7">
            <Box className="text-center">
              <Title color="light">
                <FormattedMessage id="CTA" />
              </Title>
              <Text mb={4} color="light" opacity={0.7}>
                <FormattedMessage id="Create_your_account" />
              </Text>
              <div className="d-flex flex-column align-items-center pt-3">
                <Link to="https://host.meftah.app/TenantRegistration/Register?editionId=1&subscriptionStartType=Free">
                  <Button mb={2}>
                    <FormattedMessage id="call_to_action_1" />
                  </Button>
                </Link>
                <Text
                  variant="small"
                  fontSize={"14px"}
                  color="light"
                  opacity={0.7}
                >
                  <FormattedMessage id="call_to_action_sub" />
                </Text>
              </div>
            </Box>
          </Col>
        </Row>
      </Container>
    </Box>
  </>
);

export default CTA;
