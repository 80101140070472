import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { Title, Section, Box, Text } from '../../components/Core'
import { device } from '../../utils'

import imgMobile from '../../assets/image/svg/content_image_2.svg'
import imgContentBg from '../../assets/image/svg/content_image_2_bg.svg'

// import icon3dModel from "../../assets/image/png/icon-3d-model.png";
// import iconLayout from "../../assets/image/png/icon-layot-25.png";

// const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
//   <Box
//     {...rest}
//     className={`d-flex align-items-start justify-content-start ${className}`}
//   >
//     <Box mr={4}>
//       <img src={iconImage} alt="" />
//     </Box>
//     <Box>
//       <Title variant="card" fontSize="24px" mb={3}>
//         {title}
//       </Title>
//       <Text variant="small">{children}</Text>
//     </Box>
//   </Box>
// );

const ImgStyled = styled.img`
  max-width: 100% !important;
  margin-left: -10% !important;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    margin-left: 0px;
  }
`

const Content2 = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section
      className="Section_Content2"
      style={{
        background: `url(${imgContentBg}) no-repeat right`,
        backgroundSize: `55%`,
      }}
    >
      <Container>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 mb-lg-0">
            <div
              className="pl-5"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="" className="img-fluid" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className="pt-5 pt-lg-0">
              <Title>
                <FormattedMessage id="content_2_header" />
              </Title>
              <Text>
                <FormattedMessage id="content_2_desc" />
              </Text>
              {/* <Box mt={4} pt={3}>
                <ContentCard
                  title="Faster Development UI Kits"
                  iconImage={icon3dModel}
                  mb={4}
                >
                  With lots of unique blocks, you can easily build a page
                  without coding. Build your next landing page quickly.
                </ContentCard>
                <ContentCard
                  title="Ultra Responsive Layout"
                  iconImage={iconLayout}
                >
                  With lots of unique blocks, you can easily build a page
                  without coding. Build your next landing page quickly.
                </ContentCard>
              </Box> */}
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
)

export default Content2
