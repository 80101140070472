import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
//import { saturate, lighten } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "gatsby-plugin-intl";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";
import imgHeroTab from "../../assets/image/svg/heroImage.svg";
import homePageBG from "../../assets/image/svg/homePageBG.svg";

const SectionStyled = styled(Section)`
  background-image: url(${homePageBG});
`;

const ImgRight = styled(Box)`
  position: relative;
  left: -100px;
  @media ${device.md} {
    position: absolute;
    top: 0;
    max-width: 80%;
    min-width: 80%;
    right: 0;
    left: auto;
    transform: translateX(25%);
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled className="position-relative">
        <Container>
          <Row className="justify-content-center align-items-center position-relative">
            <Col md="5" className="order-md-2 position-static">
              <ImgRight className="HeroImg">
                <img
                  src={imgHeroTab}
                  alt=""
                  className="img-fluid"
                  data-aos="fade-left"
                  data-aos-duration="750"
                  data-aos-delay="1000"
                  data-aos-once="true"
                />
              </ImgRight>
            </Col>
            <Col md="7" className="order-md-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                  <Title variant="hero" color="secondary">
                    <FormattedMessage id="slogan" />
                  </Title>
                  <Text mb={4} color="black" opacity={0.7}>
                    <FormattedMessage id="slogan_sub" />
                  </Text>
                  <div className="d-flex flex-column align-items-start pt-3">
                    <Link to="https://host.meftah.app/TenantRegistration/Register?editionId=1&subscriptionStartType=Free">
                      <Button mb={2}>
                        <FormattedMessage id="call_to_action_1" />
                      </Button>
                    </Link>
                    <Text
                      variant="small"
                      fontSize={"14px"}
                      color="black"
                      opacity={0.7}
                    >
                      <FormattedMessage id="call_to_action_sub" />
                    </Text>
                  </div>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
